import { Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class ToastService {

  constructor(
    private _toastCtrl: ToastController
  ) { }

  /**
   * Show toast
   *
   * @param   {object}  options  Ionic toast properties
   * @return  {void}
   */

  show = (options: ToastOptions = {}): void => {

    /** Merge options with defaults */

    this._toastCtrl.create(Object.assign({

      message: 'Ok!',
      duration: 5000,
      color: 'success'
    }, options))

      /** Show toast */

      .then(result => {

        result.present();
      });
  }

}
