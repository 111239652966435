
export function upsertObjectIntoArray<T extends { [key: string]: any } = any>(
  array: T[],
  newItem: T | T[],
  key: string = 'id'
): T[] {
  const newItems = Array.isArray(newItem) ? newItem : [newItem];
  const updatedArray = [...array];

  for (const item of newItems) {
    const index = array.findIndex((obj) => obj[key] === item[key]);
    if (index === -1) {
      // If the object doesn't exist in the array, add it
      updatedArray.push(item);
    } else {
      // If the object already exists in the array, update it
      updatedArray[index] = { ...item };
    }
  }

  return updatedArray;

}
