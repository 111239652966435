import { LANGUAGES, Language } from './../utils/languages.util';
import { Injectable } from '@angular/core';
import { LocalstorageKey } from '../utils/local-storage.util';
import { LocalStorageService } from './local-storage.service';
import { getBrowserLang, TranslocoService } from '@ngneat/transloco';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public languages: Language[] = LANGUAGES;

  public get selectedLanguage() {
    return this._storage.get<string>(LocalstorageKey.Language);
  }

  constructor(
    private _storage: LocalStorageService,
    private _translocoService: TranslocoService
  ) {

  }

  initLang = () => {
    const preferredLang: string = this.getPreferredLanguage();
    this.setLang(preferredLang);
  }

  getPreferredLanguage = (): string => {
    const localStorageLanguage = this._storage.get<string>(LocalstorageKey.Language);
    const browserLanguage = getBrowserLang();
    return localStorageLanguage || browserLanguage;
  }

  setLang = (lang: string) => {
    this._storage.set({ key: LocalstorageKey.Language, value: lang });
    this._translocoService.setActiveLang(lang);
  }

}
