import { Injectable } from '@angular/core';
import { ModalController, ModalOptions } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { Observable, from, map, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  public constructor(
    private readonly _modalCtrl: ModalController
  ) { }

  public open = <T>(options: ModalOptions): Observable<OverlayEventDetail<T>> => {
    return from(
      this._modalCtrl.create(options)
    )
      .pipe(
        tap((modal: HTMLIonModalElement) => modal.present()),
        switchMap((modal: HTMLIonModalElement) => modal.onDidDismiss<T>()),
      )
  }

}
