import { PaginationData } from '@ngneat/elf-pagination';
import { PaginatedResponseMeta } from '@tcc-mono/shared/data-access';
export class ElfUtil {

  static convertMetaToElfPagination = (meta: PaginatedResponseMeta): PaginationData<number> => {
    return {
      currentPage: meta?.current_page || 1,
      total: meta?.total || 0,
      perPage: meta?.per_page || 0,
      lastPage: meta?.last_page || 1,
    };
  }
}
