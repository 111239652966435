import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core.module';
import { ClassifactWebShellFeatureModule } from '@tcc-mono/classifact/web/shell/feature';
import { TranslocoRootModule } from './transloco-root.module';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    ClassifactWebShellFeatureModule,
    TranslocoRootModule,
    IonicModule.forRoot()
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
