import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

export class SelectedText {
  value: string;
  start: number;
  end: number;
  fullText?: string;
}

@Directive({
  selector: '[textSelection]'
})
export class TextSelectionDirective {
  private _selectedText: SelectedText;

  // Set an id on the parentNode surrounding the selectable text, to prevent selection of all texts on screen
  @Input() public parentNodeId: string;

  @HostListener('document:selectionchange', ['$event'])
  public onSelectionChange(event: Event): void {
    this._setSelection();
  }

  @HostListener('mouseup')
  public onMouseUp(): void {
    this._emitText();
  }

  @HostListener('touchend', ['$event'])
  public onTouchSelection(event: TouchEvent): void {
    this._setSelection();
    this._emitText();
  }

  @Output() public onTextSelect = new EventEmitter<SelectedText>();

  private _setSelection(): void {
    const selection: Selection = window.getSelection();

    if (selection.toString() && (this.parentNodeId ? window.getSelection().focusNode.parentElement.id === this.parentNodeId : true)) {
      this._selectedText = {
        value: selection.toString(),
        start: selection.focusOffset < selection.anchorOffset ? selection.focusOffset : selection.anchorOffset,
        end: selection.focusOffset > selection.anchorOffset ? selection.focusOffset : selection.anchorOffset,
        fullText: selection.anchorNode.textContent
      }
    }
  }

  private _emitText(): void {
    if (this._selectedText) {
      const selection: Selection = window.getSelection();

      this.onTextSelect.emit(this._selectedText);
      this._selectedText = null;

      selection.removeAllRanges();
    }
  }
}
