import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectify'
})
export class StringToObjectPipe implements PipeTransform {
  transform = (value: string) => {
    return JSON.parse(value);
  }
}
