import { Pipe, PipeTransform } from '@angular/core';
import { EntityProperty } from '@tcc-mono/shared/data-access';


@Pipe({
  name: 'propertyValue'
})
export class PropertyValuePipe implements PipeTransform {

  public transform = (properties: EntityProperty[], code: string) => {
    const prop: EntityProperty = properties?.find(p => p.code === code);

    return prop?.value || '';
  }

}
