import { IconName } from '@fortawesome/fontawesome-svg-core';

// export class AnimationUtil {
export class IAnimationModel {
  name?: string;
  className: AnimationClass;
  icon?: IconName;
}

export enum AnimationClass {
  None = 'no-animation',
  Fast = 'animated-fast',
  Medium = 'animated-medium',
  Slow = 'animated-slow'
}

export const ANIMATIONS: IAnimationModel[] = [
  {
    name: 'Geen',
    className: AnimationClass.None,
    icon: 'gauge-min'
  },
  {
    name: 'Langzaam',
    className: AnimationClass.Slow,
    icon: 'gauge-low'
  },
  {
    name: 'Normaal',
    className: AnimationClass.Medium,
    icon: 'gauge-high'
  },
  {
    name: 'Snel',
    className: AnimationClass.Fast,
    icon: 'gauge-max'
  }
]


// }
