export interface IAppConfig {
  production: boolean;
  apiUrl: string;
  appCode: string;
  authentication?: IAppConfigAuthentication;
  sanctumAuth?: ISanctumAuth;
}

export interface IAppConfigAuthentication {
  clientId: string;
  scope: string;
  redirectUrl: string;
  responseType: string;
  codeChallengeMethod: string;
  renewTimeBeforeTokenExpiresInSeconds: number;
  postLogoutRedirectUrl: string;
  useTenants?: boolean;
  appCallback?: string;
}

export interface ISanctumAuth {
  appLogo?: string;
  appTitle?: string;
  appSubtitle?: string;
  strategy: string;
}
