import { switchMap, tap } from 'rxjs/operators';
import { LoadingOptions } from '@ionic/angular';
import { BaseRepository } from '@tcc-mono/shared/data-access';
import { LoaderService } from '@tcc-mono/shared/ui/services';
import { defer, finalize, Observable } from 'rxjs';


export function withRepoLoading(repo: BaseRepository<any>) {
  return function <T>(source: Observable<T>) {
    return defer(() => {
      repo.setLoading(true);
      return source.pipe(finalize(() => repo.setLoading(false)));
    });
  };
}

export function withLoading(loading: LoaderService, options?: LoadingOptions) {
  return function <T>(source: Observable<T>) {
    return defer(() => {
      return loading.start(options)
        .pipe(
          switchMap(() => source),
          finalize(() => loading.dismiss())
        )
    });
  };
}

export function downloadToDisk(fileName?: string) {
  return function <globalThis>(source: Observable<globalThis.Blob>) {
    return defer(() => {
      return source.pipe(
        tap((blob: globalThis.Blob) => {
          const fileUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = fileUrl;
          a.download = fileName || 'results.xlsx';
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(fileUrl);
            document.body.removeChild(a);
          }, 0);
        }),
      )
    })
  }
}
