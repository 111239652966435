export enum FontAwesomeType {
  Solid = 'fas',
  Regular = 'far',
  Light = 'fal',
  Thin = 'fat',
  DuoTone = 'fad',
  SolidSharp = 'fass'
}


